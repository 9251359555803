export const state = {
    clients: [],

    //-1 represents that no specific user has been selected
    selected_client_id: null,
    selected_client_details: [],
    selected_client_orders: [],
    //this is will be used store a list of the clients details from the clients array so that we cn render the dom faster, withotu waiting for api call
    selectedFiltered_client_details: null,

    client_users: [],

    selected_client_userToEdit: null,
};

export const getters = {};

export const actions = {
    /*
        clients
    */
    getClients({ commit }) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/clients/all")
                .then((response) => {
                    commit("setClientData", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    saveClient({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/clients/save", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // eslint-disable-next-line
    saveProfileQuestionAnswers({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/clients/save_question_answers", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    deleteClient({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/clients/delete", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    setSelectedClientId({ commit }, id) {
        commit("setSelectedClientId", id);
    },
    //eslint-disable-next-line
    setSelectedClientDetails({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/clients/client", {
                    params: requestObject,
                })
                .then((response) => {
                    commit("setSelectedClientData", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },

    // keep for experimentation (reloading and stuff), will remove the lag on the display for each client's detail page because it calls from the clients state and not from an api request
    setFilteredSelectedClientDetails({ commit, state }) {
        const user = state.clients.find((element) => {
            if (element.id == state.selected_client_id) {
                return element;
            }
        });
        commit("setFilteredSelectedClientDetails", user);
    },

    /*
        client users
    */
    //the id to be passed will be url
    //eslint-disable-next-line
    setClientUsers({ commit, state }, id) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/admin/clientusers", {
                    params: { client_id: id },
                })
                .then((response) => {
                    commit("setClientUsers", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    createClientUser({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/admin/createclientuser", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },

    //the id to be passed will be url
    setSelectedClientUserToEdit({ commit }, id) {
        commit("setSelectedClientUserToEdit", id);
    },
    //eslint-disable-next-line
    updateClientUser({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/admin/updateuser", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    deleteClientUser({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/admin/deleteuser", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    /*
        client orders 
    */
    //eslint-disable-next-line
    deleteClientOrder({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/orders/delete", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // pass the id of the user to be delted
    //eslint-disable-next-line
    deleteClient({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/clients/delete", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    activateClient({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/clients/activate_membership", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
};

export const mutations = {
    setClientData(state, payload) {
        state.clients = payload;
    },
    setSelectedClientId(state, payload) {
        state.selected_client_id = payload;
    },
    setSelectedClientData(state, payload) {
        state.selected_client_details = payload;
        state.selected_client_orders = payload.orders;
        //eslint-disable-next-line
        console.log("seelcted client etails ", state.selected_client_details);
    },
    setClientUsers(state, payload) {
        state.client_users = payload;
    },
    setFilteredSelectedClientDetails(state, payload) {
        state.selectedFiltered_client_details = payload;
    },
    setSelectedClientUserToEdit(state, payload) {
        state.selected_client_userToEdit = payload;
    },
};
